<template>
  <div>
    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingResource ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <!-- <b-row v-if="isEditingResource">
        <b-col class="d-flex flex-row">
          <b-badge variant="dark" class="mb-3 mr-2" v-text="getLastAccessText"></b-badge>
          <b-badge variant="success" class="mb-3 mr-2" v-text="getCreationDate"></b-badge>
          <b-badge variant="warning" class="mb-3" v-text="getModificationDate"></b-badge>
        </b-col>
      </b-row> -->

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <FormulateForm
          ref="datosContactoInversionistaForm"
          name="datosContactoInversionistaForm"
          v-model="formValues"
          :errors="response.errors"
          @submit="submit"
        >
          <div class="accordion mt-2" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Domicilio del inversionista
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <b-row>
                    <b-col cols="12" md="4">
                      <!-- Calle -->
                      <FormulateInput
                        name="calle"
                        label="Calle"
                        type="text"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <!-- Número -->
                      <FormulateInput
                        name="no_exterior"
                        label="No. Exterior"
                        type="text"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <!-- Número interior-->
                      <FormulateInput
                        name="no_interior"
                        label="No. Interior"
                        type="text"
                      />
                    </b-col>
                  </b-row>
                  <!-- Referencias -->
                  <FormulateInput
                    name="referencia"
                    label="Referencia"
                    type="text"
                  />
                  <b-row>
                    <b-col cols="12" md="4">
                      <!-- Colonia -->
                      <FormulateInput
                        name="colonia"
                        label="Colonia"
                        type="text"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <!-- Código postal -->
                      <FormulateInput
                        name="codigo_postal"
                        label="Código postal"
                        type="text"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <!-- Localidad -->
                      <FormulateInput
                        name="localidad"
                        label="Localidad"
                        type="text"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="4">
                      <!-- Colonia -->
                      <FormulateInput
                        name="municipio"
                        label="Municipio"
                        type="text"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <!-- Código postal -->
                      <FormulateInput
                        name="estado"
                        label="Estado"
                        type="text"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <!-- Localidad -->
                      <FormulateInput
                        name="pais"
                        label="País"
                        type="text"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Medios de contacto del inversionista
                  </button>
                </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="mb-3">
                    <strong>Contacto 1</strong>
                  </div>
                  <b-row>
                    <b-col cols="12" md="6">
                      <FormulateInput
                        name="contacto1"
                        label="Nombre completo"
                        type="text"
                      />
                    </b-col>

                    <b-col cols="12" md="6">
                      <FormulateInput
                        name="correo1"
                        label="Correo electrónico principal"
                        type="text"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <FormulateInput
                        name="correo1a"
                        label="Correo electrónico secundario"
                        type="text"
                      />
                    </b-col>

                    <b-col cols="12" md="6">
                      <FormulateInput
                        name="telefono1"
                        label="Teléfono"
                        type="text"
                      />
                    </b-col>
                  </b-row>

                  <div class="mb-3">
                    <strong>Contacto 2</strong>
                  </div>
                  <b-row>
                    <b-col cols="12" md="12">
                      <FormulateInput
                        name="contacto2"
                        label="Nombre completo"
                        type="text"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <FormulateInput
                        name="correo2"
                        label="Correo electrónico"
                        type="text"
                      />
                    </b-col>

                    <b-col cols="12" md="6">
                      <FormulateInput
                        name="telefono2"
                        label="Teléfono"
                        type="text"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                    Datos del contador
                  </button>
                </h5>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                <div class="card-body">
                  <!-- Formulario para contador -->
                  <b-row>
                    <!-- Nombre -->
                    <b-col cols="12" md="6">
                      <FormulateInput
                        name="razon_social_contador"
                        label="Razón social"
                        type="text"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <!-- RFC -->
                      <FormulateInput
                        name="rfc_contador"
                        label="RFC"
                        type="text"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="6">
                      <!-- Email -->
                      <FormulateInput
                        name="email_contador"
                        label="Correo electrónico"
                        type="email"
                      >
                        <template #element>
                          <b-input-group>
                            <input
                              v-model="formValues.email_contador"
                              type="email"
                              placeholder="usuario@dominio.com"
                              class="form-control"
                            />
                          </b-input-group>
                        </template>
                      </FormulateInput>
                    </b-col>

                    <b-col cols="12" md="6">
                      <!-- Teléfonos -->
                      <FormulateInput
                        name="telefonos_contador"
                        label="Teléfonos"
                        type="text"
                      >
                        <template #element>
                          <b-form-tags
                            class="form-control"
                            input-id="tags-basic-contador"
                            separator=" ,;"
                            remove-on-delete
                            placeholder="Agregue nuevos números telefónicos separando con espacios, comas o punto y coma"
                            add-button-text="Agregar"
                            v-model="formValues.telefonos_contador"
                          >
                          </b-form-tags>
                        </template>
                      </FormulateInput>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </FormulateForm>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.inversionistaForm ? $refs.inversionistaForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingResource"
            :isCreating="!isEditingResource"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

const formValues = () => ({
  calle: '',
  no_exterior: '',
  no_interior: '',
  referencia: '',
  colonia: '',
  codigo_postal: '',
  localidad: '',
  municipio: '',
  estado: '',
  pais: '',
  contacto1: '',
  correo1: '',
  correo1a: '',
  telefono1: '',
  contacto2: '',
  correo2: '',
  telefono2: '',
  razon_social_contador: '',
  rfc_contador: '',
  email_contador: '',
  telefonos_contador: []
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

const API_MODULE = 'datosContactoInversionistaModule'

export default {
  name: 'DatosContactoInversionistaForm',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  async created () {
    //
    // Obtiene todos los roles del sistema
    await this.$store.dispatch('roleModule/getSystemRoles')
  },

  data () {
    return {
      modal: this.value,

      isLoading: false,

      formValues: formValues(),

      currentRoles: [],

      response: response()
    }
  },

  computed: {
    //
    // User module
    ...mapState(API_MODULE, ['editingResource']),
    ...mapGetters(API_MODULE, ['isEditingResource']),

    showFormTitle () {
      if (this.isEditingResource) {
        if (this.editingResource.persona_fisica) {
          return this.isEditingResource
            ? `Editando inversionista: '${this.editingResource.persona_fisica.nombre_completo}'`
            : 'Crear nuevo inversionista'
        } else {
          return this.isEditingResource
            ? `Editando inversionista: '${this.editingResource.persona_moral.razon_social}'`
            : 'Crear nuevo inversionista'
        }
      } else {
        return 'Crear nuevo inversionista'
      }
    },

    getLastAccessText () {
      if (!this.isEditingResource) return ''

      return this.editingResource.ultimo_acceso === 'Sin registro de ingreso'
        ? this.editingResource.ultimo_acceso
        : `Último acceso: ${this.$formatDate(this.editingResource.ultimo_acceso)}`
    },

    getCreationDate () {
      if (!this.isEditingResource) return ''

      return `Creación: ${this.$customFormatDate(this.editingResource.fecha_creacion, 'DD/MMMM/YYYY')}`
    },

    getModificationDate () {
      if (!this.isEditingResource) {
        return ''
      }

      return `Modificación: ${this.$customFormatDate(
        this.editingResource.ultima_modificacion,
        'DD/MMMM/YYYY'
      )}`
    }
  },

  watch: {
    isEditingResource: {
      handler (value) {
        if (value) {
          const { id, domicilio, contacto1, correo1, correo1a, telefono1, contacto2, correo2, telefono2, contador } = this.editingResource
          this.formValues.id = id
          this.formValues.calle = domicilio.calle
          this.formValues.no_exterior = domicilio.no_exterior
          this.formValues.no_interior = domicilio.no_interior
          this.formValues.referencia = domicilio.referencia
          this.formValues.colonia = domicilio.colonia
          this.formValues.codigo_postal = domicilio.codigo_postal
          this.formValues.localidad = domicilio.localidad
          this.formValues.municipio = domicilio.municipio
          this.formValues.estado = domicilio.estado
          this.formValues.pais = domicilio.pais
          this.formValues.contacto1 = contacto1
          this.formValues.correo1 = correo1
          this.formValues.correo1a = correo1a
          this.formValues.telefono1 = telefono1
          this.formValues.contacto2 = contacto2
          this.formValues.correo2 = correo2
          this.formValues.telefono2 = telefono2
          this.formValues.razon_social_contador = contador ? contador.razon_social : null
          this.formValues.rfc_contador = contador ? contador.rfc : null
          this.formValues.email_contador = contador ? contador.email : null
          this.formValues.telefonos_contador = contador ? contador.telefonos : null

          return
        }

        this.resetForm()
      }
    },

    value: {
      immediate: true,
      handler (value) {
        this.modal = value
      }
    },

    modal: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingResource" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      this.response = response()
      this.prepareInput()

      this.isLoading = true
      const { error, message, data } = this.isEditingResource
        ? await this.updateInversionista(this.formValues)
        : await this.createInversionista(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createInversionista (form) {
      return await this.$store.dispatch(`${API_MODULE}/createResource`, form)
    },

    async updateInversionista (form) {
      return await this.$store.dispatch(`${API_MODULE}/updateResource`, {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
      if (this.formValues.fecha_nacimiento === '') this.formValues.fecha_nacimiento = undefined
      this.formValues.fecha_nacimiento = moment(this.formValues.fecha_nacimiento).format('YYYY-MM-DD')
      this.formValues.fecha_ingreso = moment(this.formValues.fecha_ingreso).format('YYYY-MM-DD')
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${API_MODULE}/setResourceToEdit`, null)
      this.$formulate.reset('datosContactoInversionistaForm')
      this.formValues = formValues()
      this.response = response()

      this.modal = false
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
