<template>
  <base-view title="Datos de Contacto" icon="users">
    <div>
      <check-authorization :requiresAuthorizations="['inversionistas indice datos contacto']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Inversionistas

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <check-authorization
                :requiresAuthorizations="['inversionistas mostrar datos contacto']"
                :overrideIf="$store.getters['inversionistaModule/isEditingResource']"
              >
                <datos-contacto-inversionista-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getResource(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <datos-contacto-inversionista-list @on-got-item="showForm = true"></datos-contacto-inversionista-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import DatosContactoInversionistaList from '@/components/Inversionistas/DatosContacto/DatosContactoInversionistaList'
import DatosContactoInversionistaForm from '@/components/Inversionistas/DatosContacto/DatosContactoInversionistaForm'

export default {
  name: 'DatosContactoInversionistaView',

  components: {
    DatosContactoInversionistaList,
    DatosContactoInversionistaForm
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: mapGetters('datosContactoInversionistaModule', ['isEditingResource'])
}
</script>
