<template>
  <div>
    <div v-if="isThereData">
      <b-pagination size="sm" align="fill"
                    v-model="currentPage"
                    :total-rows="getTotalItems"
                    :per-page="perPage"
                    :disabled="!getAvailableList"
                    aria-controls="inversionistas-table"
      ></b-pagination>
    </div>
    <!-- Table -->
    <b-table
      id="inversionistasTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- Identificación -->
      <template #cell(identificacion)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.identificacion }}
        </span>
      </template>

      <!-- Email -->
      <template #cell(email)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }" v-if="$isEmailValid(row.item.email)">
          {{ row.item.email }}
        </span>
        <span class="text-sm text-center w-100 text-danger font-weight-bold" v-else>
          {{ row.item.email }}
        </span>
      </template>

      <!-- RFC -->
      <template #cell(rfc)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.rfc }}
        </span>
      </template>

      <!-- Reside en Mérida -->
      <template #cell(reside_merida)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.reside_merida == 'S' ? 'Sí':'No' }}
        </span>
      </template>

      <!-- Año Ingreso -->
      <template #cell(anio_ingreso)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.anio_ingreso }}
        </span>
      </template>

      <!-- Tipo Contribuyente -->
      <template #cell(tipo_persona)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.tipo_persona }}
        </span>
      </template>

      <!-- Régimen Fiscal -->
      <template #cell(regimen_fiscal.descripcion)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.regimen_fiscal.descripcion }}
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="datosContactoInversionistaModule/getResourceToEdit"
            deletePath=""
            :editPermissions="['inversionistas actualizar datos contacto']"
            hideDeleteAction
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="6">
                <b-card
                  header="Información fiscal del inversionista"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-list-group flush>
                    <b-list-group-item v-if="row.item.tipo_persona">
                      <b>Tipo de persona:</b>
                      {{ row.item.tipo_persona }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.rfc">
                      <b>RFC:</b>
                      {{ row.item.rfc }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.regimen_fiscal.descripcion">
                      <b>Régimen fiscal:</b>
                      {{ row.item.regimen_fiscal.descripcion }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.identificacion">
                      <b>Identificación:</b>
                      {{ row.item.identificacion }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.tipo_persona">
                      <b>{{ row.item.tipo_persona == 'FISICA' ? 'Nombre completo' : 'Razón social' }}:</b>
                      {{ row.item.tipo_persona == 'FISICA' ? row.item.persona_fisica.nombre_completo : row.item.persona_moral.razon_social }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>

              <b-col cols="12" md="6">
                <b-card
                  header="Datos del inversionista"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-list-group flush>
                    <!-- Correo electronico -->
                    <b-list-group-item v-if="row.item.email">
                      <b>Correo electrónico:</b>
                      <span v-if="$isEmailValid(row.item.email)">
                        {{ row.item.email }}
                      </span>
                      <span v-else class="text-danger font-weight-bold">
                        {{ row.item.email }}
                      </span>
                    </b-list-group-item>

                    <!-- Año de ingreso -->
                    <b-list-group-item v-if="row.item.anio_ingreso">
                      <b>Año de ingreso:</b>
                      {{ row.item.anio_ingreso }}
                    </b-list-group-item>

                    <!-- Notas -->
                    <b-list-group-item v-if="row.item.notas">
                      <b>Notas:</b>
                      {{ row.item.notas }}
                    </b-list-group-item>

                    <div v-if="row.item.tipo_persona == 'FISICA'">
                      <!-- Fecha de nacimiento -->
                      <b-list-group-item v-if="row.item.persona_fisica.fecha_nacimiento">
                        <b>Fecha de nacimiento:</b>
                        {{ row.item.persona_fisica.fecha_nacimiento }}
                      </b-list-group-item>

                      <!-- Lugar de nacimiento -->
                      <b-list-group-item v-if="row.item.persona_fisica.lugar_nacimiento">
                        <b>Lugar de nacimiento:</b>
                        {{ row.item.persona_fisica.lugar_nacimiento }}
                      </b-list-group-item>

                      <!-- Estado civil -->
                      <b-list-group-item v-if="row.item.persona_fisica.estado_civil">
                        <b>Estado civil:</b>
                        {{ row.item.persona_fisica.estado_civil }}
                      </b-list-group-item>

                      <!-- Regimen conyugal -->
                      <b-list-group-item v-if="row.item.persona_fisica.regimen_conyugal">
                        <b>Régimen conyugal:</b>
                        {{ row.item.persona_fisica.regimen_conyugal }}
                      </b-list-group-item>

                      <!-- Tipo de identificacion -->
                      <b-list-group-item v-if="row.item.persona_fisica.tipo_identificacion">
                        <b>Tipo de identificación:</b>
                        {{ row.item.persona_fisica.tipo_identificacion }}
                      </b-list-group-item>

                      <!-- Folio de identificacion -->
                      <b-list-group-item v-if="row.item.persona_fisica.folio_identificacion">
                        <b>Folio de identificación:</b>
                        {{ row.item.persona_fisica.folio_identificacion }}
                      </b-list-group-item>
                    </div>
                    <div v-else>
                      <!-- Fecha de constitucion -->
                      <b-list-group-item v-if="row.item.persona_moral.fecha_constitucion">
                        <b>Fecha de constitución:</b>
                        {{ row.item.persona_moral.fecha_constitucion }}
                      </b-list-group-item>

                      <!-- Lugar de constitucion -->
                      <b-list-group-item v-if="row.item.persona_moral.lugar_constitucion">
                        <b>Lugar de constitución:</b>
                        {{ row.item.persona_moral.lugar_constitucion }}
                      </b-list-group-item>
                    </div>
                  </b-list-group>
                </b-card>
              </b-col>

              <b-col cols="12" md="6">
                <b-card
                  header="Dirección del inversionista"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-list-group flush>
                    <!-- Calle -->
                    <b-list-group-item v-if="row.item.domicilio.calle">
                      <b>Calle:</b>
                      {{ row.item.domicilio.calle }}
                    </b-list-group-item>

                    <!-- No. exterior -->
                    <b-list-group-item v-if="row.item.domicilio.no_exterior">
                      <b>No. exterior:</b>
                      {{ row.item.domicilio.no_exterior }}
                    </b-list-group-item>

                    <!-- No. interior -->
                    <b-list-group-item v-if="row.item.domicilio.no_interior">
                      <b>No. interior:</b>
                      {{ row.item.domicilio.no_interior }}
                    </b-list-group-item>

                    <!-- Referencia -->
                    <b-list-group-item v-if="row.item.domicilio.referencia">
                      <b>Referencia:</b>
                      {{ row.item.domicilio.referencia }}
                    </b-list-group-item>

                    <!-- Colonia -->
                    <b-list-group-item v-if="row.item.domicilio.colonia">
                      <b>Colonia:</b>
                      {{ row.item.domicilio.colonia }}
                    </b-list-group-item>

                    <!-- Código postal -->
                    <b-list-group-item v-if="row.item.domicilio.codigo_postal">
                      <b>Código postal:</b>
                      {{ row.item.domicilio.codigo_postal }}
                    </b-list-group-item>

                    <!-- Localidad -->
                    <b-list-group-item v-if="row.item.domicilio.localidad">
                      <b>Localidad:</b>
                      {{ row.item.domicilio.localidad }}
                    </b-list-group-item>

                    <!-- Municipio -->
                    <b-list-group-item v-if="row.item.domicilio.municipio">
                      <b>Municipio:</b>
                      {{ row.item.domicilio.municipio }}
                    </b-list-group-item>

                    <!-- Estado -->
                    <b-list-group-item v-if="row.item.domicilio.estado">
                      <b>Estado:</b>
                      {{ row.item.domicilio.estado }}
                    </b-list-group-item>

                    <!-- País -->
                    <b-list-group-item v-if="row.item.domicilio.pais">
                      <b>País:</b>
                      {{ row.item.domicilio.pais }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>

              <b-col cols="12" md="6">
                <b-card
                  header="Medio de pago del inversionista"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-list-group flush>
                    <!-- Institucion bancaria -->
                    <b-list-group-item v-if="row.item.institucion_bancaria">
                      <b>Institución bancaria:</b>
                      {{ row.item.institucion_bancaria.nombre }}
                    </b-list-group-item>

                    <!-- Beneficiario -->
                    <b-list-group-item v-if="row.item.beneficiario">
                      <b>Beneficiario:</b>
                      {{ row.item.beneficiario }}
                    </b-list-group-item>

                    <!-- CLABE -->
                    <b-list-group-item v-if="row.item.clabe">
                      <b>CLABE:</b>
                      {{ row.item.clabe }}
                    </b-list-group-item>

                    <!-- Forma de pago -->
                    <b-list-group-item v-if="row.item.forma_pago">
                      <b>Forma de pago:</b>
                      {{ row.item.forma_pago }}
                    </b-list-group-item>

                    <!-- No de cuenta -->
                    <b-list-group-item v-if="row.item.cuenta">
                      <b>No. de cuenta:</b>
                      {{ row.item.cuenta }}
                    </b-list-group-item>

                    <!-- No de tarjeta -->
                    <b-list-group-item v-if="row.item.num_tarjeta">
                      <b>No. de tarjeta:</b>
                      {{ row.item.num_tarjeta }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>

              <b-col cols="12" md="6">
                <b-card
                  header="Medios de contacto del inversionista"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-list-group flush>
                    <!-- Contacto1 -->
                    <b-list-group-item v-if="row.item.contacto1">
                      <b>Contacto 1:</b>
                      {{ row.item.contacto1 }}
                    </b-list-group-item>

                    <!-- Correo1 -->
                    <b-list-group-item v-if="row.item.correo1">
                      <b>Correo electrónico principal (Contacto 1):</b>
                      {{ row.item.correo1 }}
                    </b-list-group-item>

                    <!-- Correo1a -->
                    <b-list-group-item v-if="row.item.correo1a">
                      <b>Correo electrónico secundario (Contacto 1):</b>
                      {{ row.item.correo1a }}
                    </b-list-group-item>

                    <!-- Telefono1 -->
                    <b-list-group-item v-if="row.item.telefono1">
                      <b>Teléfono (Contacto 1):</b>
                      {{ row.item.telefono1 }}
                    </b-list-group-item>

                    <b-list-group-item v-if="row.item.contacto2"></b-list-group-item>

                    <!-- Contacto2 -->
                    <b-list-group-item v-if="row.item.contacto2">
                      <b>Contacto 2:</b>
                      {{ row.item.contacto2 }}
                    </b-list-group-item>

                    <!-- Correo2 -->
                    <b-list-group-item v-if="row.item.correo2">
                      <b>Correo electrónico principal (Contacto 2):</b>
                      {{ row.item.correo2 }}
                    </b-list-group-item>

                    <!-- Telefono2 -->
                    <b-list-group-item v-if="row.item.telefono2">
                      <b>Teléfono (Contacto 2):</b>
                      {{ row.item.telefono2 }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>

              <b-col cols="12" md="6" v-if="row.item.contador">
                <b-card
                  header="Datos del contador"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="overflow: auto;"
                >
                  <b-list-group flush>
                    <!-- Contador : razon social -->
                    <b-list-group-item v-if="row.item.contador.razon_social">
                      <b>Razón social:</b>
                      {{ row.item.contador.razon_social }}
                    </b-list-group-item>

                    <!-- Contador : RFC -->
                    <b-list-group-item v-if="row.item.contador.rfc">
                      <b>RFC:</b>
                      {{ row.item.contador.rfc }}
                    </b-list-group-item>

                    <!-- Contador : email -->
                    <b-list-group-item v-if="row.item.contador.email">
                      <b>Correo electrónico:</b>
                      {{ row.item.contador.email }}
                    </b-list-group-item>

                    <!-- Contador : telefonos -->
                    <b-list-group-item v-if="row.item.contador.telefonos">
                      <b>Teléfonos:</b>
                      <div class="badge badge-info ml-1" v-for="telefono in row.item.contador.telefonos" :key="telefono">
                        {{ telefono }}
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>
            </b-row>

            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const API_MODULE = 'datosContactoInversionistaModule'

export default {
  name: 'DatosContactoInversionistaList',

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'identificacion', label: 'Identificación', sortable: true },
        { key: 'email', label: 'Correo' },
        { key: 'rfc', label: 'RFC' },
        { key: 'reside_merida', label: 'Reside en Mérida' },
        { key: 'anio_ingreso', label: 'Inversionista desde' },
        { key: 'tipo_persona', label: 'Tipo de persona' },
        { key: 'regimen_fiscal.descripcion', label: 'Régimen Fiscal' },
        { key: 'show_details', label: 'Detalle' },
        { key: 'acciones', label: 'Acciones' }
      ],

      perPage: 10,

      currentPage: 1
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ]),
    ...mapGetters('userModule', [
      'isAuthUserOrCloneUser'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getResource`)

      if (error) this.$notify({ error, message }, 'Inversionistas')
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>
